import React from "react";
import { reportsTableContent } from "./Helper";
const ReportTable = () => {
  // Function to return the class based on snippet value
  const getSnippetClass = (snippet) => {
    if (snippet === "Settled") {
      return "text-[#3766EA] bg-[#DAE4FF]";
    } else if (snippet === "Declined") {
      return "text-[#DA0303] bg-[#FFD3D3]";
    } else {
      return "text-[#007A00] bg-[#BAFFB7]";
    }
  };
  return (
    <div className="w-full flex items-center mx-auto mb-6 rounded-[10px]">
      <div className="shadow-reports w-full mx-auto rounded-[10px] overflow-scroll">
        <table className="min-w-[700px] w-full table-auto rounded-[10px]">
          <thead className="border-b border-tableBorder">
            <tr>
              <th className="font-bold text-sm xl:text-base font-manrope py-3 xl:py-[18px]">
                Name
              </th>
              <th className="font-bold text-sm xl:text-base font-manrope py-3 xl:py-[18px]">
                Last 4
              </th>
              <th className="font-bold text-sm xl:text-base font-manrope py-3 xl:py-[18px]">
                Date
              </th>
              <th className="font-bold text-sm xl:text-base font-manrope py-3 xl:py-[18px]">
                Mcc
              </th>
              <th className="font-bold text-sm xl:text-base font-manrope py-3 xl:py-[18px]">
                Amount
              </th>
              <th className="font-bold text-sm xl:text-base font-manrope py-3 xl:py-[18px]">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {reportsTableContent.map((obj, i) => (
              <tr className="border-b border-[#F2F4F7]" key={i}>
                <td className="py-2 xl:py-4 text-sm xl:text-base text-lightBlack text-start md:text-center ps-4 sm:ps-6 xl:ps-0">
                  {obj.name}
                </td>
                <td className="py-2 xl:py-4 text-sm xl:text-base text-lightBlack text-start md:text-center ps-4 sm:ps-6 xl:ps-0">
                  {obj.last}
                </td>
                <td className="py-2 xl:py-4 text-sm xl:text-base text-lightBlack text-start md:text-center ps-4 sm:ps-6 xl:ps-0">
                  {obj.date}
                </td>
                <td className="py-2 xl:py-4 text-sm xl:text-base text-lightBlack text-start md:text-center ps-4 sm:ps-6 xl:ps-0">
                  {obj.mcc}
                </td>
                <td className="py-2 xl:py-4 text-sm xl:text-base text-lightBlack text-start md:text-center ps-4 sm:ps-6 xl:ps-0">
                  {obj.amount}
                </td>
                <td className="py-2 xl:py-4 text-sm xl:text-base text-lightBlack flex md:justify-center items-center ps-4 sm:ps-6 xl:ps-0">
                  <span
                    className={`h-[27px] w-[90px] sm:w-[100px] text-[15px] font-medium flex items-center justify-center rounded-full text-sm xl:text-base
                      ${getSnippetClass(obj.status)}
                      `}
                  >
                    {obj.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportTable;
