export const reportsTableContent = [
  {
    name: "John D. Doe",
    last: "1235",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$100.00",
    status: "Settled",
  },
  {
    name: "Alicia W. Doe.",
    last: "8999",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$23.99",
    status: "Declined",
  },
  {
    name: "Wade Allen Paul",
    last: "0001",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$1.39",
    status: "Declined",
  },
  {
    name: "Brittany F Roberts.",
    last: "2277",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$5001.72",
    status: "Posted",
  },
  {
    name: "Brittany F Roberts.",
    last: "2277",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$475.66",
    status: "Posted",
  },
  {
    name: "Brittany F Roberts.",
    last: "2277",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$27.41",
    status: "Posted",
  },
  {
    name: "Wade Allen Paul",
    last: "0001",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$871.41",
    status: "Settled",
  },
  {
    name: "Alicia W. Doe..",
    last: "8999",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$45.07",
    status: "Settled",
  },
  {
    name: "Wade Allen Paul",
    last: "0001",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$14.15",
    status: "Settled",
  },
  {
    name: "John D. Doe",
    last: "1235",
    date: "03/25/22",
    mcc: "3423-Merchan1",
    amount: "$0.98",
    status: "Settled",
  },
];
