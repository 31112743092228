import React from "react";
import loginImg from "../assets/images/png/login-img.png";
import Header from "../components/login/Header";
import LoginForm from "../components/login/LoginForm";

const Login = () => {
  return (
    <div className="flex sm:h-screen max-md:items-center max-md:justify-center 2xl:justify-between">
      <div className="w-full xl:max-w-[43vw] flex flex-col md:justify-center justify-between items-center bg-white px-4 h-full">
        <Header />
        <div className="w-full flex flex-col justify-center items-center sm:h-[calc(100vh-38px)] md:mt-0 py-7">
          <h4 className="text-2xl md:text-[32px] font-bold mb-2 text-center">
            Welcome!
          </h4>
          <p className="text-center custom-2xl:mb-12 mb-3 lg:mb-8 max-w-[333px] mx-auto text-sm font-normal">
            Use these awesome forms to login or create new account in your
            project for free.
          </p>
          <LoginForm />
        </div>
      </div>
      <div className="w-full bg-indigo-900 justify-center items-center hidden md:flex bg-login bg-cover shadow-login flex-col relative">
        <img
          loading="lazy"
          src={loginImg}
          alt="Login Illustration"
          className="max-w-[28vw] object-contain"
        />
      </div>
    </div>
  );
};

export default Login;
