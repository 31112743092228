import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <nav className="flex justify-center md:justify-end items-center custom-2xl:mt-[50px] mt-4 md:mt-5 lg:mt-6 md:px-5 lg:px-20 text-base lg:text-lg font-normal gap-6 lg:gap-10 w-full md:absolute top-0 right-0 z-10">
      <Link className="md:text-white text-black" to="/">
        Home
      </Link>
      <Link to="/#contact-us" className="md:text-white text-black">
        Contact
      </Link>
      <Link className="md:text-white text-black md:border-white border-black border px-7 py-1 rounded-2xl hover:bg-white hover:text-secondary transition-all duration-300">
        Login
      </Link>
    </nav>
  );
};

export default Header;
