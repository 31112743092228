import React, { Fragment, useState } from "react";
import { budgetTableContent } from "./Helper";
import TableRow from "./TableRow"; // Import your TableRow component here

const BudgetTable = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const handleToggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <div className="w-full flex items-center mx-auto mb-6 rounded-[10px]">
      <div className="shadow-reports w-full mx-auto rounded-[10px] overflow-scroll">
        <table className="min-w-[740px] w-full table-auto rounded-[10px]">
          <thead className="border-b border-tableBorder">
            <tr>
              <th className="font-bold text-sm xl:text-sm custom-2xl:text-base font-manrope py-3 xl:py-[18px]"></th>
              <th className="font-bold text-sm xl:text-sm custom-2xl:text-base font-manrope py-3 xl:py-[18px]">
                Name
              </th>
              <th className="font-bold text-sm xl:text-sm custom-2xl:text-base font-manrope py-3 xl:py-[18px]">
                Card Number
              </th>
              <th className="font-bold text-sm xl:text-sm custom-2xl:text-base font-manrope py-3 xl:py-[18px]">
                Limit
              </th>
              <th className="font-bold text-sm xl:text-sm custom-2xl:text-base font-manrope py-3 xl:py-[18px]">
                Remaining
              </th>
              <th className="font-bold text-sm xl:text-sm custom-2xl:text-base font-manrope py-3 xl:py-[18px]">
                Snippet
              </th>
            </tr>
          </thead>
          <tbody>
            {budgetTableContent.map((obj, index) => (
              <Fragment key={index}>
                <TableRow
                  obj={obj}
                  index={index}
                  isOpen={openAccordion === index}
                  handleToggle={handleToggleAccordion}
                />
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BudgetTable;
