import React from "react";
import { DropdownIcon } from "../dashboard/Icons";
import { categoryData } from "./Helper";

const Dropdown = ({
  valueSelect,
  setValueSelect,
  valueSelect2,
  setValueSelect2,
}) => {
  return (
    <div className="flex items-center gap-2 custom-2xl:gap-3">
      <p className="text-sm custom-2xl:text-base text-lightBlack font-medium">
        Restricted Categories
      </p>
      <div
        onClick={() => setValueSelect(!valueSelect)}
        className="relative z-10 cursor-pointer min-w-[263px]"
      >
        <div
          className={`border rounded-[14px] text-lightBlack text-sm custom-2xl:text-base font-medium h-[29px] flex justify-between items-center relative z-10 bg-white ${
            valueSelect ? "border-[#245BB3]" : "border-black"
          }`}
        >
          <p
            className={`text-lightBlack text-sm custom-2xl:text-base px-2 custom-2xl:px-3 border-black ${
              valueSelect ? "" : "border-r"
            }`}
          >
            {valueSelect2
              ? `${valueSelect2}`
              : "7273- Dating & Escort Services"}
          </p>
          <span
            className={`px-2 custom-2xl:px-3 h-full flex items-center justify-center ${
              valueSelect ? "bg-blueberry rotate-[180deg] rounded-l-[10px]" : ""
            }`}
          >
            <DropdownIcon
              className={`${valueSelect ? "stroke-white" : "stroke-black"}`}
            />
          </span>
        </div>
        {valueSelect && (
          <div className="absolute w-full left-0 top-0 rounded-[14px] bg-white shadow-custom pt-8 z-[-1] h-[118px] overflow-y-auto category_scrollbar">
            {categoryData.map((obj, i) => (
              <p
                onClick={() => {
                  setValueSelect2(obj.title);
                  setValueSelect(false);
                }}
                key={i}
                className={`text-sm xl:text-base text-[#8B8B8B] py-2 xl:py-2.5 px-2 xl:px-4 cursor-pointer hover:bg-lightRed hover:text-[#245BB3] ${
                  valueSelect2 === obj.title
                    ? "text-[#245BB3] bg-[#DAE4FF]"
                    : ""
                }`}
              >
                {obj.title}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
