import React, { useState } from "react";
import { GoogleIcon } from "../common/Icons";
import { FacebookIcon } from "./../common/Icons";
import { Link, useNavigate } from "react-router-dom";
import { CloseEyeIcon, EyeIcon } from "../dashboard/Icons";

const LoginForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      navigate("/cards");
    }, 2000);
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <article className="max-w-[452px] w-full px-4 custom-2xl:px-5 py-4 sm:py-6 custom-2xl:py-10 rounded-md sm:rounded-[15px] shadow-reports">
      <button className="w-full mb-4 custom-2xl:mb-5 py-3 custom-2xl:py-[18px] px-4 bg-white text-sm sm:text-base font-medium border border-[#17161B] rounded-[4px] flex items-center justify-center text-black gap-[6px]">
        <GoogleIcon />
        Continue with Google
      </button>
      <button className="w-full mb-4 custom-2xl:mb-5 py-3 custom-2xl:py-[18px] px-4 bg-white text-sm sm:text-base font-medium border border-[#17161B] rounded-[4px] flex items-center justify-center text-black gap-[6px]">
        <FacebookIcon />
        Continue with Facebook
      </button>
      <span className="text-center mb-4 custom-2xl:mb-5 text-[#5B5B58] flex justify-center text-sm sm:text-base">
        OR
      </span>
      <form onSubmit={handleSubmit} action="">
        <div className="relative w-full mb-4 custom-2xl:mb-8">
          <input
            type="email"
            required
            className="block w-full px-4 py-2 text-sm text-gray-900 bg-white border border-black rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer h-[50px] custom-2xl:h-[64px]"
          />
          <label
            htmlFor="email"
            className="absolute text-base custom-2xl:text-md text-black duration-300 transform -translate-y-4 scale-75 top-1 custom-2xl:top-2 z-10 origin-[0] bg-white px-2 left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-5"
          >
            Email
          </label>
        </div>
        <div className="relative w-full">
          <div
            className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <EyeIcon /> : <CloseEyeIcon />}
          </div>
          <input
            type={showPassword ? "text" : "password"}
            required
            className="block w-full px-4 py-2 text-sm text-gray-900 bg-white border border-black rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer h-[50px] custom-2xl:h-[64px]"
          />
          <label
            htmlFor="password"
            className="absolute text-base custom-2xl:text-md text-black duration-300 transform -translate-y-4 scale-75 top-1 custom-2xl:top-2 z-10 origin-[0] bg-white px-2 left-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-5"
          >
            Password
          </label>
        </div>
        <button
          className={`profile_btn py-3 2xl:py-4 w-full text-center mt-4 custom-2xl:mt-5 mb-4 custom-2xl:mb-[30px] text-base flex justify-center items-center ${
            isLoading ? "pointer-events-none" : ""
          }`}
          type="submit"
        >
          {isLoading ? (
            <span className="loader border-2 border-t-2 border-white h-6 w-6 rounded-full animate-spin block"></span>
          ) : (
            "Submit"
          )}
        </button>
      </form>
      <div className="flex justify-between items-center">
        <Link
          to="/"
          className="duration-300 hover:text-black text-base font-medium text-[#5B5B58]"
        >
          Forgot Password?
        </Link>
        <Link
          to="#"
          className="duration-300 hover:text-blueberry text-sm text-secondary font-medium"
        >
          New user
        </Link>
      </div>
    </article>
  );
};

export default LoginForm;
