import userImg1 from "../../assets/images/png/budget/user1.png";
import userImg2 from "../../assets/images/png/budget/user2.png";
import userImg3 from "../../assets/images/png/budget/user3.png";
import userImg4 from "../../assets/images/png/budget/user4.png";

export const budgetTableContent = [
  {
    img: userImg1,
    name: "John D. Doe",
    cardNumber: "4321-4567-0002-1235",
    limit: "$1000",
    remaining: "$366",
    snippet: "No Hard Limit",
  },
  {
    img: userImg2,
    name: "Alicia W. Doe.",
    cardNumber: "4321-3333-6758-8999",
    limit: "$500",
    remaining: "($122)",
    snippet: "Over limit",
  },
  {
    img: userImg3,
    name: "Wade Allen Paul",
    cardNumber: "4321-4567-3245-0001",
    limit: "$450",
    remaining: "$450",
    snippet: "Hard limit set",
  },
  {
    img: userImg4,
    name: "Brittany F Roberts.",
    cardNumber: "4321-4567-6799-2277",
    limit: "$1000",
    remaining: "$0",
    snippet: "No Hard Limit",
  },
];
export const categoryData = [
  { title: "7297 - Massage Parlors" },
  { title: "7342 - Disinfecting Services" },
  { title: "7297 - Massage Parlors" },
  { title: "7342 - Disinfecting Services" },
  { title: "7297 - Massage Parlors" },
  { title: "7342 - Disinfecting Services" },
  { title: "7297 - Massage Parlors" },
  { title: "7342 - Disinfecting Services" },
  { title: "7297 - Massage Parlors" },
  { title: "7342 - Disinfecting Services" },
  { title: "7297 - Massage Parlors" },
  { title: "7342 - Disinfecting Services" },
];
