import React from "react";
import AccordionContent from "./AccordionContent";

const TableRow = ({ obj, index, isOpen, handleToggle }) => {
  // Function to return the class based on snippet value
  const getSnippetClass = (snippet) => {
    if (snippet === "No Hard Limit") {
      return "text-[#3766EA] bg-[#DAE4FF]";
    } else if (snippet === "Over limit") {
      return "text-[#DA0303] bg-[#FFD3D3]";
    } else {
      return "text-[#007A00] bg-[#BAFFB7]";
    }
  };
  return (
    <>
      <tr
        className="border-b border-[#F2F4F7] cursor-pointer"
        onClick={() => handleToggle(index)}
      >
        <td className="py-2 xl:py-4 text-sm xl:text-sm custom-2xl:text-base text-lightBlack text-start md:text-center ps-4 lg:ps-6 xl:ps-0">
          <img
            className="w-[52px] rounded-sm ms-auto"
            src={obj.img}
            alt="user img"
          />
        </td>
        <td className="py-2 xl:py-4 text-sm xl:text-sm custom-2xl:text-base text-lightBlack text-start md:text-center ps-4 lg:ps-6 xl:ps-0">
          {obj.name}
        </td>
        <td className="py-2 xl:py-4 text-sm xl:text-sm custom-2xl:text-base text-lightBlack text-start md:text-center ps-4 lg:ps-6 xl:ps-0">
          {obj.cardNumber}
        </td>
        <td className="py-2 xl:py-4 text-sm xl:text-sm custom-2xl:text-base text-lightBlack text-start md:text-center ps-4 lg:ps-6 xl:ps-0">
          {obj.limit}
        </td>
        <td className="py-2 xl:py-4 text-sm xl:text-sm custom-2xl:text-base text-lightBlack text-start md:text-center ps-4 lg:ps-6 xl:ps-0">
          {obj.remaining}
        </td>
        <td className="py-2 xl:py-4 text-sm xl:text-sm custom-2xl:text-base text-lightBlack ps-4 lg:ps-6 xl:ps-0 pe-2 xl:pe-0">
          <span
            className={`h-[27px] w-[126px] text-[15px] font-medium flex items-center justify-center rounded-full text-sm xl:text-sm custom-2xl:text-base md:mx-auto ${getSnippetClass(
              obj.snippet
            )}`}
          >
            {obj.snippet}
          </span>
        </td>
      </tr>
      {isOpen && <AccordionContent index={index} />}
    </>
  );
};

export default TableRow;
