import React, { useState } from "react";
import { PencilIcon } from "../dashboard/Icons";
import Dropdown from "./Dropdown";

const AccordionContent = ({ index }) => {
  const [valueSelect, setValueSelect] = useState(false);
  const [valueSelect2, setValueSelect2] = useState("");
  return (
    <tr>
      <td colSpan={6}>
        <div className="bg-[#F5F4FF] duration-500 flex justify-between items-center py-[18px] text-nowrap px-4 gap-6">
          <div className="flex items-center gap-2 custom-2xl:gap-3">
            <p className="text-lightBlack text-sm custom-2xl:text-base font-medium">
              Spending Limit
            </p>
            <div className="border border-black rounded-[10px] text-lightBlack text-sm custom-2xl:text-base overflow-hidden h-[29px] flex items-center w-[119px]">
              <label
                htmlFor={`limitInput-${index}`}
                className="px-2 custom-2xl:px-3 border-r border-black h-full flex items-center"
              >
                $
              </label>
              <input
                id={`limitInput-${index}`}
                className="focus-visible:outline-none bg-transparent placeholder:text-lightBlack px-2.5 [&::-webkit-inner-spin-button]:appearance-none w-full"
                type="number"
                placeholder="450"
              />
            </div>
          </div>
          <div className="flex items-center gap-2 custom-2xl:gap-3">
            <input
              className="custom-checkbox h-7 w-7 cursor-pointer"
              type="checkbox"
              id={`enforce-limit-${index}`}
            />
            <label
              htmlFor={`enforce-limit-${index}`}
              className="text-lightBlack text-sm custom-2xl:text-base font-medium cursor-pointer"
            >
              Enforce Hard Limit
            </label>
          </div>
          <button className="profile_btn flex items-center justify-center gap-2 custom-2xl:gap-3 group py-2.5 px-5 text-base custom-2xl:text-xl">
            <PencilIcon />
            Edit Categories
          </button>
          <Dropdown
            valueSelect={valueSelect}
            setValueSelect={setValueSelect}
            valueSelect2={valueSelect2}
            setValueSelect2={setValueSelect2}
          />
        </div>
      </td>
    </tr>
  );
};

export default AccordionContent;
